@import 'src/styles/colors';

.filtersContainer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .form {
    display: flex;
    flex-direction: row;

    .formItem {
      margin: 0 13px 0 13px;

      .select {
        width: 161px;
      }
    }
  }
}

.subtitleContainer {
  padding: 8px 0 16px;

  .subtitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .subtitleDescription {
    color: $ui_medium_gray;
    font-size: 14px;
  }
}

.block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  min-height: 120px;

  .infoIcon {
    position: absolute;
    right: 22px;
    top: 22px;
    color: $ui_medium_gray;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f2f8;
    border-radius: 8px;
    width: 70px;
    height: 70px;
    margin-right: 24px;
  }

  .blockTitle {
    font-size: 30px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 4px;
    font-family:sans-serif;
  }

  .blockSubtitle {
    font-size: 16px;
    line-height: 24px;
    color: $ui_medium_gray;
  }

  .blockInfo {
    font-size: 14px;
    line-height: 24px;
    color: $ui_medium_gray;
  }

  .diffBadge {
    position: absolute;
    right: 22px;
    bottom: 22px;
  }
}

.blockSmall {
  min-height: 80px;
  padding: 16px;

  .blockTitle {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .blockSubtitle {
    color: #30353f;
  }

  .iconContainer {
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }

  .diffBadge {
    right: 16px;
    bottom: 16px;
  }

  .infoIcon {
    right: 16px;
    top: 16px;
  }
}

.analyticsSection {
  margin-bottom: 40px;
}

.engagementContainer {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  min-width: calc(100% + 24px);
  margin-left: -12px;
  margin-right: -12px;

  .block {
    flex: 3;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    min-width: 800px;
    margin: 0 12px 24px 12px;
  }

  .popularChallengesContainer {
    border-right: 1px solid #f4f3f3;
    padding: 20px;
    height: 100%;
  }

  .popularChallengesDataContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .popularChallengesListContainer {
    margin-right: 20px;
    max-width: 50%;
  }

  .engagementBlockTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .showMoreButton {
      span {
        text-decoration: underline;
      }
    }
  }

  .popularChallenge {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 16px;

    .bulletSign {
      position: relative;
      top: 6px;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      line-height: 24px;
      margin-right: 12px;
      background: #680079;
    }

    &:nth-child(2) {
      .bulletSign {
        background: #69aa9e;
      }
    }

    &:nth-child(3) {
      .bulletSign {
        background: #f78f49;
      }
    }

    .challengeInfoContainer {
      max-width: calc(100% - 24px);
    }

    .popularChallengeTitle {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .popularChallengeUsers {
      font-size: 14px;
      line-height: 24px;
      color: $ui_medium_gray;
    }
  }

  .challengesCompletion {
    padding: 20px 0;
    height: 100%;

    .engagementBlockTitle {
      padding: 5px 20px 0;
    }

    .challengesCompletionData {
      height: calc(100% - 44px);
    }

    .challengesCompletionBlock {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      height: 50%;

      &:first-child {
        border-bottom: 1px solid #f4f3f3;
      }

      .completedChallengeChart {
        position: relative;
        margin-right: 12px;

        .completedChallengeChartLabel {
          position: absolute;
          top: 26px;
          left: 22px;
          font-weight: 600;
          font-size: 14px;
        }

        :global {
          .g2-html-annotation {
            display: none !important;
          }
        }
      }

      .completedChallengeChartGreen {
        .completedChallengeChartLabel {
          color: #69aa9e;
        }
      }

      .completedChallengeChartRed {
        .completedChallengeChartLabel {
          color: #ef7065;
        }
      }

      .completedChallengeTitle {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }

      .completedChallengeDesc {
        font-size: 14px;
        line-height: 24px;
        color: $ui_medium_gray;
      }

      .completedChallengeUsers {
        font-weight: 600;
        color: $primary_color;
      }

      .engagementEmpty {
        margin: 0;
      }
    }
  }

  .engagementEmpty {
    text-align: center;
    margin: 32px 0;
    color: $ui_medium_gray;
  }

  .mostClaimedReward {
    flex: 1;
    margin: 0 0 48px 0;
    min-width: 290px;
    display: flex;
    flex-direction: column;

    .rewardsBlock {
      flex: 1;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      margin: 0 12px 12px 12px;
    }

    .rewardsBlockEmpty {
      flex: 1;
      margin: 12px 0 0 12px;
    }

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      margin-right: 10px;
      background: #f8f2f8;
      border-radius: 4px;
    }

    .rewardTitleContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 12px;
    }

    .rewardTitle {
      font-weight: 500;
      line-height: 22px;
    }

    .rewardNameContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 44px);
    }

    .rewardName {
      width: 50%;
    }

    .diffBadge {
      font-size: 14px;
      margin-left: auto;
    }

    .engagementEmpty {
      margin: 24px 0;
    }
  }
}
