@import 'src/styles/colors';
@import 'src/styles/font';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .centralCard {
    margin: 52px auto 0 auto;
    position: initial;
  }

  .loginForm {
    width: 100%;
    margin: 24px 0 0 0;

    .forgotButtonContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .forgotButton {
        padding: 0;
      }
    }

    .input {
      height: 38px;
    }

    .signInButton {
      width: 100%;
      height: 40px;
      margin: 23px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .errorMessage {
      color: #cf1322;
      text-align: center;
      margin: 12px 0 12px 0;
    }

    .or {
      width: 100%;
      text-align: center;
    }

    .thirdLoginButton {
      margin: 12px 0 0 0;
      width: 100%;
    }

    .googleIcon {
      margin: 0 8px 0 0;
      div {
        height: 24px;
        width: 24px;
      }
    }
  }

  .legalBlock {
    margin: 22px 0 22px 0;
    color: $ui_medium_gray;
    width: 100%;
    text-align: center;

    a {
      color: $ui_medium_gray;
      text-decoration: underline;
    }
  }
}
.toast {
  background: none;
  box-shadow: none;
}
.alertSuccess {
  background-color: #d9f7be;
  font-family: $default_font;
}
