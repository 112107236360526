@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
