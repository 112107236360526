@import 'src/styles/colors';

.formContainer {
  display: flex;
  flex-direction: row;
  margin: 0 180px 34px 0;

  .formCard {
    flex: 1;
    margin: 0 0 0 0;
    width: 100%;

    .formItem {
      margin: 32px 0 0 0;

      .activityInputWrap {
        display: flex;
        flex-direction: row;

        .activityAddButton {
          margin: 0 0 0 8px;
          color: $primary_color;
        }
      }
    }

    .activityTagsWrap {
      display: block;
      flex-direction: row;
      margin: 24px 0 0 0;

      .tag {
        margin: 8px 8px 0 0;
      }
    }

    .formTitle {
      margin-top: 48px;
    }
  }
}
