@import 'src/styles/colors';

.image {
  .uploadContainer {
    display: flex;
    flex-direction: row;

    .upload {
      width: 102px;
      .uploadImage {
        width: 100%;
      }
      .uploadButton {
        .uploadText {
          color: #00000073;
          margin: 8px 0 0 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
    .uploadHint {
      width: 222px;
      color: $ui_medium_gray;
      margin: 0 0 0 16px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .uploadButtons {
    display: flex;
    flex-direction: row;

    .deleteButton {
      margin: 0 0 0 8px;
    }
  }
}
