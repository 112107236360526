@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 12px;
  margin-top: 7px;

  width: 345px;
  height: 32px;

  /* Neutral / 1 */

  background: #ffffff;
  /* Text/Disabled */

  border: 1px solid #bdc0c1;
  border-radius: 2px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.text {
  height: 22px;

  /* Vitaance/body-secondary */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Text/Disabled */

  color: #bdc0c1;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

// Mobile

@media only screen and (max-width: 576px) {
  .container {
    box-sizing: border-box;
    margin-top: 7px;
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 12px;

    width: 343px;
    height: 32px;

    /* Neutral / 1 */

    background: #ffffff;
    /* Text/Disabled */

    border: 1px solid #bdc0c1;
    border-radius: 2px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .text {
    height: 22px;

    /* Vitaance/body-secondary */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    /* Text/Disabled */

    color: #bdc0c1;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
