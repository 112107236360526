@import 'src/styles/colors';

.actionColumn {
  padding: 0 !important;
  text-align: center !important;

  .tableActions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 104px;

    .actionButton {
      cursor: pointer;
      margin: 9px;
    }
    .actionButtonPrimary {
      color: #0050b3;
      margin: 9px;
      cursor: pointer;
    }
  }
}

.searchBarContainer {
  padding: 14px 8px 28px 0;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .searchField {
    :global {
      .anticon-search {
        color: $primary_color;
      }
    }
  }
}

.emptyContainer {
  height: 100%;

  .empty {
    background-color: #fafafa;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
