@import 'src/styles/colors';
@import 'src/styles/font';

.scanQrCodeTextContainer {
  margin-bottom: 40px;
  margin-top: 40px;

  .scanQrCodeText {
    font-family: 'Poppins';
    font-weight: 600;
  }
}

.row {
  display: flex;
  flex-direction: row;
  height: 151px;

  .rightContainer {
    padding-left: 40px;

    .firstImage {
      padding-bottom: 10px;
    }

    .lastImage {
      padding-top: 10px;
    }
  }
}

// Mobile & Tablet

@media only screen and (max-width: 876px) {
  .scanQrCodeTextContainer {
    display: none;
  }

  .row {
    .qrContainer {
      background-color: #FEF6F6;

      .qrCode {
        display: none;
      }
    }

    .rightContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      .firstImage {
        width: 200px;
        padding-right: 6px;
        padding-bottom: 0px;
        margin-left: -40px;
      }

      .lastImage {
        width: 200px;
        padding-left: 6px;
        padding-top: 0px;
      }
    }
  }
}
