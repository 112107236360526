@import 'src/styles/colors';
@import 'src/styles/font';

.header {
  font-family: $default_font;
  background-color: $ui_light_color;
  height: 48px;
  padding: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .logo {
      margin: 0 0 0 16px;
    }
    .logo > div {
      height: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .logoText {
      margin: 3px 0 0 13px;
      color: $primary_color;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .headerButton {
      color: $primary_color;
      height: 100%;
      width: 48px;
    }
    .userButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      padding: 0 12px 0 12px;
      cursor: pointer;

      .userImg {
        margin: 0;
        padding: 0;
        height: 24px;
        width: 24px;
        max-width: 24px;
        max-height: 24px;
        border-radius: 100%;
      }
      .userName {
        margin: 0 0 0 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: $primary_color;
      }
    }
    .langSelector {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $primary_color;
      margin: 0 16px 0 0;
      min-width: 58px;

      :global {
        .ant-select-selector {
          display: flex;
          flex-direction: row;
          align-items: center;

          background: none;
          border: none;
        }
        .ant-select-arrow {
          color: $primary_color;
        }
      }
    }
  }
}
