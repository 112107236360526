@import 'src/styles/colors';
@import 'src/styles/font';

:global {
  .ant-tooltip-inner > a {
    color: #ffffff;
  }
}

.menu {
  font-family: $default_font;
  width: 100%;
  height: 100%;
  margin: 48px 0 0 0 !important;

  :global {
    .ant-menu-item-selected {
      border-width: 3px solid $primary_color;
      background-color: rgba(104, 0, 121, 0.05) ;
    }
    .ant-menu-sub-item-selected {
      border-right: 3px solid $primary_color;
      background-color: rgba(104, 0, 121, 0.05) ;
    }
  }

  .menuSub {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    ul {
      background-color: #ffffff;
    }
    :global {
      .ant-menu-item::after {
        border-right: 3px solid $primary_color;
      }
    }
    .menuItem {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .hideButton {
    bottom: 0 !important;
    position: absolute !important;
    box-shadow: inset 0 1px 0 #f0f0f0;
    width: 100%;
  }
}
