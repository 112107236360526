@import 'src/styles/colors';

.wrap {
  margin: 0 23px 0 0;
  height: 100%;
}

.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }
    }
    .manageButton {
      margin: 0 8px 0 0;
      color: $primary_color;
    }
  }
}

:global(.modalContainer) {
  position: initial;
}

.empty {
  background-color: #fafafa;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .noUserButton {
    color: $primary_color;
  }

  :global {
    .ant-empty-image {
      height: auto;
    }
  }
}

.shopIcon {
  height: 64px;
  width: 56px;
  margin-bottom: 20px;

  svg {
    height: 100%;
    width: 100%;
    color: $primary_color;
  }
}

.basicTag {
  color: #eb2f96;
}
.flexibleTag {
  color: #13c2c2;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;

  .editButton {
    color: #0050b3;
  }
  .editButton:focus {
    color: #0050b3;
  }
  .editButton:hover {
    color: #0050b3;
  }
  .deleteButton {
    color: black;
  }
}
