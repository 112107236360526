@import 'src/styles/colors';

.userTitle {
  cursor: pointer;
  color: $primary_color;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.titleImage {
  height: 40px;
  width: 40px;
  margin: 0 8px 0 0;
  object-fit: cover;
}
.tableActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 102px;

  .actionButton {
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 4px 0 4px;
  }
  .actionButtonPrimary {
    color: #0050b3;
  }
}
.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
    .manageButton {
      margin: 0 8px 0 0;
    }
  }
}
.typeIcon {
  margin-right: 8px;
  font-size: 18px;
  color: $ui_gray;
}
.statusWrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  .statusBadge {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .resendButton {
    margin: 0 0 0 0;
  }
}

.companiesList {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
