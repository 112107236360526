@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  height: 119px;
  width: 465px;

  .heading {
    width: 345px;
    height: 119px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    color: #1d1d1b;
  }
}

// Mobile

@media only screen and (max-width: 800px) {
  .container {
    display: flex;
    align-items: center;
    width: 345px;
    height: 91px;
    padding: 20px;
    margin-top: 16px;

    .heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #1d1d1b;
    }
  }
}
