@import 'src/styles/colors';
@import 'src/styles/font';

.confirmIcon {
  color: #1890ff !important;
}

:global {
  .ant-btn-primary {
    background-color: $primary_color ;
    font-family: 'Poppins';
    color: white;
  }
  .ant-btn-default {
    border-color: $primary_color !important;
    color: $primary_color !important;
    font-family: 'Poppins';
  }
}
