@import '../../../styles/colors';

.radiosContainer {
  width: 100%;
  display: flex;
  flex-direction: row;

  .radioOption {
    margin: 0 120px 0 0;
  }
}

.selectOption {
  :global {
    .ant-select-item-option-selected {
      background-color: $ui_light_color !important;
    }
  }
}
