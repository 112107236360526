@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  background: #FEF6F6;
  height: 100vh;
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100vh;
      align-items: center;
      justify-content: center;
      background-color: #FEF6F6;

      .vitaanceTextContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 130px;
        margin-bottom: 40px;
        position: relative;

        .vitaanceText {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 38px;
          display: flex;
          align-items: center;
          color: #9d50ef;
        }
      }
    }
  }

  .characters {
    display: none;
  }
}

.innerRowLeft {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-self: center;

  .containerHeading {
    min-width: 350px;
    max-width: 400px;
    margin-bottom: 10px;
    text-align: left;

    .heading {
      width: 345px;
      height: 119px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 50px;
      color: #1d1d1b;
    }
  }

  .footer {
    position: relative;
    top: 175px;
    width: 100%;

    .footerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 390px;
      height: 80px;
      margin-top: 10px;

      a > span {
        color: #5a6265;
        background-color: transparent;
        text-decoration: underline;
      }

      .policy {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #5a6265;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      .copyrightUnderline {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #5a6265;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-top: -10px;
      }
    }
  }
}


.rowRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #FFEBE5;
  overflow: hidden;

  .textPos {
    width: 95%;
    text-align: center;
      align-items: center;
      justify-content: center;

    .textStyle {
      font-family: 'DM Serif Display';
      font-style: normal;
      font-weight: 400;
      font-size: 70px;
      line-height: 96px;
      text-align: center;
      color: #1d1d1b;
    }
  }
}


// Mobile & Tablet

@media only screen and (max-width: 876px) {
  .container {
    display: flex !important;
    flex-direction: column;
    background: #FEF6F6;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;

      .rowLeft {
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        justify-content: center;
        position: relative;

        &.rowLeftMiddle {
          top: 0px;
        }

        .vitaanceTextContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 130px;
          margin-bottom: '38px';
          margin-top: 60px;
          position: relative;
          left: -0%;

          .logo {
            width: 44.06px;
            height: 29.91px;
          }

          .vitaanceText {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 38px;
            display: flex;
            align-items: center;
            color: #9d50ef;
          }
        }
      }
    }

    .characters {
      display: flex;
      width: 90%;
    }

    .innerRowLeft {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
  
      .containerHeading {
        padding-left: 20px;
      }
  
    }
  
    .footer {
      position: relative;
      top: 120px;
      background: #FEF6F6;
      width: 100%;
  
      .footerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 375px;
        height: 40px;
        padding: 30px;
        margin-bottom: 30px;
        margin-top: -20px;
  
        a > span {
          color: #5a6265;
          background-color: transparent;
          text-decoration: underline;
        }
  
        .policy {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .copyrightUnderline {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-top: -10px;
        }
      }
    }
  }

  
  
  .rowRight {
    display: none;
  }
}
