@import 'src/styles/colors';
@import 'src/styles/font';

.margin {
  display: flex;
  align-self: center;
  align-items: center;

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 345px;
    height: 40px;
    background: #1d1d1b;
    mix-blend-mode: normal;
    border-radius: 100px;
    color: #fff;
    margin-top: 45px;
    border-radius: 100px;
  }

  .button:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 345px;
    height: 40px;
    border-color: #bdc0c1 !important;
    opacity: 0.5;
    background: #bdc0c1 !important;
    mix-blend-mode: normal;
    border-radius: 100px;
    color: #fff;
    margin-top: 45px;
  }
}

.marginMobile {
  display: flex;
  align-self: center;
  align-items: center;

  .buttonMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    width: 345px;
    height: 40px;
    background: #1d1d1b;
    mix-blend-mode: normal;
    border-radius: 100px;
    color: #fff;
    margin-top: 45px;
    border-radius: 100px;
  }
}
