@import 'src/styles/colors';

.formWrapper {
  max-width: 100%;
  display: flex;
  flex-direction: row;

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;

    .alert {
      width: 100%;
      background-color: #e6f7ff;
      border-color: #91d5ff;
      margin: 0 0 27px 0;

      :global {
        .anticon {
          color: #1890ff;
        }
      }
    }
  }
  .dataColumn {
    margin: 0 0 0 55px;
    min-width: 311px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    .dataBlock {
      margin: 23px 0 0 0;

      .statusWrapper {
        display: flex;
        flex-direction: row;
      }

      .dataBlockValue {
        color: #00000073;
      }
    }
  }
}

.card {
  width: 100%;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: $primary_color;
    margin-bottom: 28px;
  }
  .formTitle {
    margin: 44px 0 28px 0;
  }
  .subTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: -18px 0 18px 0;
    color: #5A6265;
  }
  .basicInfoContainer {
    margin: 0 0 0 0;
    display: flex;
    flex-direction: row;

    .basicInfo {
      flex: 1;
      margin: 0 34px 0 0;
    }
    .imageForm {
      flex: 1;
      margin: 0 0 0 0;
    }
  }
  .categoryWrap {
    margin-bottom: 50px;
  }
  .dateInput {
    width: 100% !important;
    min-width: 100%;
  }
  .advancedParams {
    :global {
      .ant-collapse-header {
        color: $primary_color;
      }
    }
  }
}

.layoutPrincipal {
  background-color: #ffffff !important;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selectField {
  width: 100%;
  :global {
    .ant-select-selection-item {
      width: 1px;
    }
  }
}

.inputWithLengthCounter {
  :global(.ant-input-suffix) {
    position: absolute;
    right: 0;
    bottom: -23px;
  }
}
