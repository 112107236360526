@import '../../../../../styles/colors';

.personalInfo {
  width: 866px;

  .formTitle {
    margin: 24px 0 0 0;
  }

  .formItem {
    margin: 24px 0 0 0;

    .dateInput {
      width: 100%;

      :global {
        .ant-picker-suffix {
          color: $primary_color;
        }
      }
    }

    .positionIcon {
      color: $primary_color;
    }
  }

  .phoneNumberContainer {
    display: flex;
    align-items: center;

    .phoneCountryCode {
      max-width: 150px;
      margin-right: 12px;
    }
  }

  .buttons {
    margin: 40px 0 0 0;

    .button {
      margin: 0 15px 0 0;
    }
  }

  .userDataValue {
    font-weight: 500;
  }
}

.validationError {
  color: red;
}
