@import 'src/styles/colors';

.layout {
  margin-top: 20px;
  .content {
    padding: 0 -30px 0 0px;
    background-color: #ffffff;

    .card {
      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $primary_color;
      }
      .sectionContainer {
        display: flex;
        flex-direction: row;
        width: 100%;

        .basicInfo {
          flex: 1;
          margin: 22px 0 0 0;
          width: 100%;

          .form {
            .basicRowContainer {
              display: flex;
              flex-direction: row;

              .itemLeftContainer {
                flex: 1;
                margin: 0 18px 32px 0;
              }
              .itemRightContainer {
                flex: 1;
                margin: 0 0 32px 0;
              }
            }
          }
        }
      }
    }

    .inputError {
      border: 1px solid $danger_color;
    }
  }
}

.companyDetailsValue {
  font-weight: 500;
}

.searchResult {
  font-weight: normal;
}
