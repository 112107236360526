@import 'src/styles/colors';
@import 'src/styles/font';

.wrap {
  display: flex;
  height: 100%;

  .wrapperLeft {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 400px;
    background: #FEF6F6;
  }

  .wrapperRight {
    width: 50%;
    background-color: #FFEBE5;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-width: 400px;
    flex-direction: column;
  }

  @media screen and (max-width: 800px) {
    & {
      display: block;

      .wrapperLeft {
        width: 100%;
      }
      .wrapperRight {
        display: none;
      }
    }
  }

  .centralBlock {
    flex: 0.5;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .centralCard {
    width: 349px;
    top: 50%;
    margin-top: -250px;
    position: absolute;

    .key {
      margin-bottom: 12px;
    }
  }

  .title {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    color: $caviar_black;
    text-transform: capitalize;
  }

  .helpText {
    margin: 28px 0 0 0;
    color: $ui_medium_gray;
    width: 100%;
    font-size: 14px;
    font-family: 'Inter';
  }

  .hintText {
    margin: 10px 0 0 0;
    color: $ui_light_gray;
    width: 100%;
    font-size: 12px;
    font-family: $default_font;
  }

  .form {
    width: 100%;
    margin: 24px 0 0 0;
    font-family: $default_font;

    .forgotButtonContainer {
      display: flex;
      flex-direction: row;
      justify-content: end;

      .forgotButton {
        padding: 0;
      }
    }

    .input {
      height: 38px;
    }

    .signInButton {
      width: 100%;
      height: 40px;
      margin: 23px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: $caviar_black;
      border-radius: 100px;
      font-family: 'Inter';
      font-size: 18px;
      color: #FFFFFF;
    }

    .myDisabledButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
      width: 345px;
      height: 40px;
      border-color: #1d1d1b;
      opacity: 0.5;
      background: #1d1d1b;
      mix-blend-mode: normal;
      border-radius: 100px;
      color: #fff;
      margin-top: 48px;
    }

    .errorMessage {
      color: #cf1322;
      text-align: center;
      margin: 12px 0 12px 0;
    }

    .successMessage {
      text-align: center;
      margin: 12px 0 12px 0;
    }

    .or {
      width: 100%;
      text-align: center;
    }

    .thirdLoginButton {
      margin: 12px 0 0 0;
      width: 100%;
    }

    .googleIcon {
      margin: 0 8px 0 0;
      div {
        height: 24px;
        width: 24px;
      }
    }

    .logInLink {
      padding: 0;
    }
  }

  .legalBlock {
    margin: 22px 0 22px 0;
    color: $ui_medium_gray;
    width: 100%;
    text-align: center;
    font-family: $default_font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;

    a {
      color: $ui_medium_gray;
      text-decoration: underline;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-self: flex-start;
    align-content: center;
    margin-left: 19px;
    text-decoration: none;

    .logo {
      margin-top: 3px;
      fill: #9D50EF;
      stroke: #9D50EF;
    }

    .logoText {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 38px;
      color: #9D50EF;
      margin-left: 17px;
    }
  }

  .bubbleContainerTop {
    position: absolute;
    top: 0;
    left: -70px;
  }
  .bubbleContainerRight {
    position: absolute;
    top: 20px;
    right: 0px;
  }
  .bubbleContainerBottom {
    position: absolute;
    bottom: 20px;
    left: -120px;
  }

  .mainTitle {
    font-family: $primary_font;
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    text-align: center;
    z-index: 1;
    color: $caviar_black;
    line-height: 96px;
    padding: 0 100px;
  }
}
