@import 'src/styles/colors';
@import 'src/styles/font';

.rootLayout {
  height: 100vh;
  background-color: #ffffff;

  .headerContainer {
    position: fixed;
    z-index: 100;
    width: 100%;

    :global {
      .ant-layout-header {
        font-family: $default_font;
        background-color: $ui_light_color;
        height: 48px;
      }
    }
  }
  .sider {
    background-color: #ffffff;
    position: fixed;
    z-index: 99;
    height: 100%;
  }
  .contentContainer {
    background: #ffffff;
    .content {
      position: relative;
      margin: 48px 0 0 200px;
      padding: 20px;
      background: #ffffff;
      overflow: auto;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .contentCollapsed {
      margin: 48px 0 0 40px;
    }
  }

  .footer {
    color: $ui_medium_gray;
    background: #ffffff;

    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    margin: 10px 0 0 0;
    padding: 0 0 0 12px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 98;
  }
}
