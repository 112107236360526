@import 'src/styles/colors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .centralCard {
    margin: 80px auto 0 auto;
    position: absolute;
  }

  .title {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    width: 100%;
    color: $primary_color;
  }

  .helpText {
    margin: 28px 0 0 0;
    color: $ui_medium_gray;
    width: 100%;
  }

  .form {
    width: 100%;
    margin: 24px 0 0 0;

    .forgotButtonContainer {
      display: flex;
      flex-direction: row;
      justify-content: end;

      .forgotButton {
        padding: 0;
      }
    }

    .input {
      height: 38px;
    }

    .signInButton {
      width: 100%;
      height: 40px;
      margin: 23px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .errorMessage {
      color: #cf1322;
      text-align: center;
      margin: 12px 0 12px 0;
    }

    .successMessage {
      text-align: center;
      margin: 12px 0 12px 0;
    }

    .or {
      width: 100%;
      text-align: center;
    }

    .thirdLoginButton {
      margin: 12px 0 0 0;
      width: 100%;
    }

    .googleIcon {
      margin: 0 8px 0 0;
      div {
        height: 24px;
        width: 24px;
      }
    }

    .logInLink {
      padding: 0;
    }
  }

  .legalBlock {
    margin: 22px 0 22px 0;
    color: $ui_medium_gray;
    width: 100%;
    text-align: center;

    a {
      color: $ui_medium_gray;
      text-decoration: underline;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-self: flex-start;
    align-content: center;
    justify-content: center;
    margin-left: 20px;

    .logo {
      margin-top: 3px;
    }

    .logoText {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 38px;
      color: #680079;
      margin-left: 17px;
    }
  }
}
