@import 'src/styles/colors';

.articleTitle {
  cursor: pointer;
  color: $primary_color;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

  .titleImage {
    height: 36px;
    width: 36px;
    margin: 0 8px 0 0;
    object-fit: cover;
  }
}
.tableActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 102px;

  .actionButton {
    cursor: pointer;
  }
  .actionButtonPrimary {
    color: #0050b3;
  }
}
.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }
    }
    .manageButton {
      margin: 0 8px 0 0;
      color: $primary_color;
    }
  }
}
.bulkMenu {
  margin: 0 0 12px 0;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background-color: $ui_light_color;
  border-radius: 15px;

  .bulkMenuCounter {
    margin: 0 48px 0 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .bulkMenuDropdown {
    margin: 0 8px 0 0;
  }
}
.typeIcon {
  margin-right: 8px;
  font-size: 18px;
  color: $ui_gray;
}
