@import 'src/styles/colors';

.wrap {
  margin: 35px;

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 14px;

    .cancelButton {
      margin: 0 8px 0 0;
      color: $primary_color;
    }
  }
}
.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }
    }
    .manageButton {
      margin: 0 8px 0 0;
      color: $primary_color;
    }
  }
}
