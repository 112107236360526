@import 'src/styles/colors';

.modalBack {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #262626b3;
  height: 100%;
  width: 100%;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modalContainer {
  max-height: 100%;
  overflow: auto;
  margin: 44px;
  width: 90%;
  background-color: #ffffff;
}
.modalContainerHidden {
  display: none;
}
