.ant-page-header {
  padding: 16px 24px 16px 0 !important;
}
.errorNotification {
  &.ant-notification-notice {
    background: #5a6265;
    padding: 8px 16px;
    max-width: 300px;

    .ant-notification-notice-message {
      color: #ffffff;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 22px;
    }

    .ant-notification-notice-close {
      display: none;
    }
  }
}
