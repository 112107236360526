@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  text-align: center;
  .text {
    height: 20px;
    /* Paragraph/Small */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    text-align: center;

    /* Primary/Caviar black */
    color: #1d1d1b;

    .link {
      color: #8039cc;
    }
  }
}
