.leftImage {
  position: fixed;
  bottom: 60px;
  left: 40px;
  z-index: -1;
}
.rightImage {
  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: -1;
}
