@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  width: 466px;
  height: 100%;
  background: #fafafa;
  border-radius: 8px;
  padding: 20px;

  .heading {
    width: 389px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1d1d1b;
  }

  .paragraph {
    width: 418px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a6265;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .children {
    width: 100%;
    margin-top: 29px;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 346px;
    height: 100%;
    background: #fafafa;
    border-radius: 8px;
    padding: 10px;

    .heading {
      width: 307px;
      height: 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1d1d1b;
    }

    .paragraph {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 307px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #5a6265;
    }
  }
}
