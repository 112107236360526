@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: #FEF6F6;
  
  .rowLeftContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background: #FEF6F6;
    justify-content: center;
    align-items: center;

    .header {
      position: absolute;
      top: 20px;
      width: 47%;

      .langText {
        margin-left: 4px;
      }

      .headerContainer{
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    .vitaanceLogoRes {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 345px;

      .img {
        display: none;
      }

      .vitaanceTextContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 130px;
        margin-bottom: '38px';

        .vitaanceText {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 38px;
          /* identical to box height, or 190% */

          display: flex;
          align-items: center;

          /* Complementary/Plum */

          color: #9d50ef;
        }
      }
    }

    .welcomeContainer {
      width: 350px;
      min-height: 150px;
      position: relative;
      top: 65px;

      .welcomeText {
        height: 150px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 50px;
        color: #1d1d1b;
      }
    }

    .joinContainer {
      margin-top: 23px;
      width: 344px;
      height: 48px;

      .joinText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5a6265;
      }
    }

    .buttonContainer {
      position: relative;
      top: 65px;

      .button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 345px;
        height: 40px;
        background: #1d1d1b;
        mix-blend-mode: normal;
        border-radius: 100px;
        color: #fff;
        margin-top: 48px;
        border-radius: 100px;
      }

      .button:hover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 345px;
        height: 40px;
        border-color: #bdc0c1 !important;
        opacity: 0.5;
        background: #bdc0c1 !important;
        mix-blend-mode: normal;
        border-radius: 100px;
        color: #fff;
        margin-top: 48px;
      }
    }

    .footer {
      position: absolute;
      bottom: 0px;

      .footerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 390px;
        height: 80px;
        margin-top: 10px;

        a > span {
          color: #5a6265;
          background-color: transparent;
          text-decoration: underline;
        }

        .policy {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .copyrightUnderline {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-top: -10px;
        }
      }
    }
  }

  .carousel {
    width: 50%;
    background: '#CC98F4';
    height: 100vh;
  }
}

.resonsive {
  height: 259px;
}
// Mobile && Tablet
@media only screen and (max-width: 876px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .rowLeftContainer {
      display: flex;
      align-items: center;
      width: 100%;
      height: 120%;
      background: #FEF6F6;

      .header {
        position: absolute;
        top: 20px;
        width: 97%;

        .langText {
          margin-left: 4px;
        }
  
        .headerContainer{
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
      
      .vitaanceLogoRes {
        position: relative;
        top: 70px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 96px;

        .img {
          display: flex;
        }

        .vitaanceTextContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          width: 130px;
          margin-bottom: 38px;
          margin-top: 30px;

          .logo {
            width: 44.06px;
            height: 29.91px;
          }

          .vitaanceText {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 38px;
            display: flex;
            align-items: center;
            color: #9d50ef;
          }
        }
      }

      .welcomeContainer {
        position: relative;
        top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 84px;

        .welcomeTextContainer {
          display: flex;
          width: 460px;
          align-items: center;

          .welcomeText {
            display: flex;
            width: 460px;
            height: 84px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 42px;
            text-align: center;
            color: #1d1d1b;

            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }

      .joinContainer {
        position: relative;
        top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 48px;

        .joinText {
          text-align: center;
        }
      }

      .buttonContainer {
        position: relative;
        top: -50px;
      }

      .footer {
        position: relative;
        top: 0px;
        background: #FEF6F6;

        .footerContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 375px;
          height: 40px;
          height: 80px;
          padding: 30px;
          margin-bottom: 30px;
          margin-top: -20px;

          a > span {
            color: #5a6265;
            background-color: transparent;
            text-decoration: underline;
          }

          .policy {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #5a6265;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
          }

          .copyrightUnderline {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #5a6265;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
            margin-top: -10px;
          }
        }
      }
    }

    .carousel {
      display: none;
    }
  }
}
