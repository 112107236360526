@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  background: #FEF6F6;
  height: 100vh;
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .rowCheckItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 5px;

    .checkItemActive {
      display: flex;
      box-sizing: border-box;
      background: #64DB94;
      border: 1px solid #64DB94;
      height: 16px;
      width: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    .checkItemUnactive {
      display: flex;
      box-sizing: border-box;
      background: #BDC0C1;
      border: 1px solid #BDC0C1;
      height: 16px;
      width: 16px;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    .checkLine {
      height: 8px;
      width: 8px;
    }

    .checkText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #00000073;
      margin-left: 10px;
      margin-top: -1px;
    }
  }

  .logoPos {
    position: absolute;
    left: 16px;
    top: 20px;
  }

  .rowLeft {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .createAccContainer {
      margin-bottom: 12px;
      .createAccText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #1d1d1b;
      }
    }

    .userContainer {
      margin-bottom: 34px;
      flex-direction: column;

      .userText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #5a6265;
      }

      .userEmailText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #1d1d1b;
      }
    }

    .progressPosition {
      position: fixed;
      top: 50px;
      background-color: #FEF6F6;
      z-index: 10;
      height: 50px;

      .circularActive {
        display: flex;
        box-sizing: border-box;
        background: #9d50ef;
        border: 1px solid #9d50ef;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularCompleted {
        display: flex;
        box-sizing: border-box;
        background: #64db94;
        border: 1px solid #64db94;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularUnActive {
        display: flex;
        box-sizing: border-box;
        border: 1px solid #bdc0c1;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .completedText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #1d1d1b;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .activeText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #9d50ef;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .unActiveText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #5a6265;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .numberActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .numberUnActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #bdc0c1;
      }

      .line {
        width: 45px;
        height: 0px;
        border: 1px solid #bdc0c1;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 12px;
        margin-right: 12px;
        margin-top: 12px;
      }

      .lineActive {
        width: 45px;
        height: 0px;
        border: 0.5px solid #64db94;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 12px;
      }
    }

    .innerRowLeft {
      width: 345px;

      .passwordSpacing {
        margin-top: 16px;
        margin-bottom: 8px;
      }

      .password {
        margin-bottom: 16px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #30353f;

        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;

        .button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          width: 345px;
          height: 40px;
          background: #1d1d1b;
          mix-blend-mode: normal;
          border-radius: 100px;
          color: #fff;
          margin-top: 48px;
        }

        .myDisabledButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          width: 345px;
          height: 40px;
          border-color: #1d1d1b;
          opacity: 0.5;
          background: #1d1d1b;
          mix-blend-mode: normal;
          border-radius: 100px;
          color: #fff;
          margin-top: 48px;
        }
      }
    }

    .footer {
      position: relative;
      top: 175px;

      .footerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 390px;
        height: 80px;
        margin-top: 10px;

        a > span {
          color: #5a6265;
          background-color: transparent;
          text-decoration: underline;
        }

        .policy {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .copyrightUnderline {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-top: -10px;
        }
      }
    }
  }

  .rowRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #FFEBE5;
    overflow: hidden;

    .textPos {
      display: flex;
      width: 95%;
      text-align: center;
      align-items: center;
      justify-content: center;

      .textStyle {
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 70px;
        line-height: 96px;
        color: #1d1d1b;
      }
    }

    .progressPosition {
      position: fixed;
      top: 50px;
      width: 100%;
      border: 1px solid #333;

      .circularActive {
        display: flex;
        box-sizing: border-box;
        background: #9d50ef;
        border: 1px solid #9d50ef;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularCompleted {
        display: flex;
        box-sizing: border-box;
        background: #64db94;
        border: 1px solid #64db94;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularUnActive {
        display: flex;
        box-sizing: border-box;
        border: 1px solid #bdc0c1;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .completedText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #1d1d1b;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .activeText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #9d50ef;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .unActiveText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #5a6265;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .numberActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .numberUnActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #bdc0c1;
      }

      .line {
        width: 45px;
        height: 0px;
        border: 0.5px solid #bdc0c1;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 12px;
      }

      .lineActive {
        width: 45px;
        height: 0px;
        border: 0.5px solid #64db94;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 12px;
      }
    }

    .innerRowLeft {
      display: flex;
      height: 100%;
      width: 345px;
      margin-top: 122px;
      padding-top: 200px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      top: -100px;

      .overflowPos {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      }
    }
  }
}

// Tablet

@media only screen and (max-width: 876px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #FEF6F6;
    align-items: center;
    align-self: center;
    height: 100vh;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
    }

    .logoPos {
      position: absolute;
      left: 16px;
      top: 20px;
    }

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      align-items: center;
    }

    .rowRight {
      display: none;

      .textPos {
        display: none;

        .textStyle {
          display: none;
        }
      }
    }
  }
}

// Mobile

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #FEF6F6;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
    }

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: center;

      .logoPos {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-left: -16px;
      }
      
      .createAccContainer {
        width: 349px;
        margin-top: -30px;

        .createAccText {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #1d1d1b;
        }
      }

      .userContainer {
        width: 345px;
        height: 47px;
        flex-direction: column;

        .userText {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #5a6265;
        }
        
        .userEmailText {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #1d1d1b;
        }
      }

      .progressPosition {
        position: fixed;
        width: 100%;
        margin-top: 30px;
        background-color: #FEF6F6;
        z-index: 0;
        height: 100px;

        .circularActive {
          display: flex;
          box-sizing: border-box;
          background: #9d50ef;
          border: 1px solid #9d50ef;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .circularCompleted {
          display: flex;
          box-sizing: border-box;
          background: #64db94;
          border: 1px solid #64db94;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .circularUnActive {
          display: flex;
          box-sizing: border-box;
          border: 1px solid #bdc0c1;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .completedText {
          display: none;
        }

        .activeText {
          display: none;
        }

        .unActiveText {
          display: none;
        }

        .numberActive {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        .numberUnActive {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #bdc0c1;
        }

        .line {
          width: 45px;
          height: 0px;
          border: 1px solid #bdc0c1;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-left: 12px;
          margin-right: 12px;
          margin-top: 12px;
        }

        .lineActive {
          width: 45px;
          height: 0px;
          border: 0.5px solid #64db94;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-left: 22px;
          margin-right: 22px;
          margin-top: 12px;
        }
      }

      .innerRowLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        top: 50px;

        .passwordSpacing {
          width: 88%;
          margin-top: 16px;
          margin-bottom: 8px;
        }

        .password {
          margin-bottom: 16px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;

          color: #30353f;

          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .bottom {
          display: flex;
          flex-direction: column;
          width: 95%;
          align-items: center;
        }
        .footer {
          position: relative;
          top: 50px;
          
          .footerContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 375px;
            height: 40px;
            height: 80px;
            padding: 30px;
            margin-bottom: 30px;
  
            a > span {
              color: #5a6265;
              background-color: transparent;
              text-decoration: underline;
            }
  
            .policy {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              color: #5a6265;
              flex: none;
              order: 0;
              align-self: stretch;
              flex-grow: 0;
            }

            .copyrightUnderline {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              color: #5a6265;
              flex: none;
              order: 0;
              align-self: stretch;
              flex-grow: 0;
              margin-top: -10px;
            }
          }
        }
        
      }
    }

    .rowRight {
      display: none;

      .textPos {
        display: none;

        .textStyle {
          display: none;
        }
      }
    }
  }
}
