@import 'src/styles/colors';

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center !important ;
  height: 100vh;
  min-width: 360px;
  padding-bottom: 47px;

  .centralCard {
    position: absolute;
    font-family: 'Poppins';
    padding: 36px 36px 10px 36px;
    margin-top: 80px;

    @media screen and (max-height: 750px) {
      position: initial;
      margin: 80px 0 20px 0;
    }
  }

  .title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    width: 100%;
    color: $primary_color;
  }

  .helpText {
    margin: 28px 0 0 0;
    color: $ui_medium_gray;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }

  .form {
    width: 100%;
    margin: 24px 0 0 0;

    .input {
      height: 38px;
      font-family: 'Poppins';
    }

    .signInButton {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    }

    .myDisabledButton {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      border-radius: 2px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-color: #680079 !important;
      opacity: 0.5;
      background: #680079 !important;
    }

    .errorMessage {
      color: #cf1322;
      text-align: center;
      margin: 12px 0 12px 0;
    }
  }

  .legalBlock {
    position: relative;
    margin: 0 0 0 0;
    color: $ui_medium_gray;
    width: 100%;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;

    @media screen and (max-height: 750px) {
      padding-bottom: 40px;
    }

    a {
      color: $ui_medium_gray;
      text-decoration: underline;
    }

    .questions {
      font-style: normal;
      font-weight: normal;
      font-size: 16px !important;
      line-height: 24px !important;
      margin-bottom: 17px;
      font-family: 'Poppins';
    }
  }

  .termsAndConditionsLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    .termsAndConditionsLink {
      color: $primary_color;
    }
  }

  .logoContainer {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-self: flex-start;
    align-content: center;
    justify-content: center;
    margin-left: 20px;

    @media screen and (max-height: 750px) {
      position: fixed;
      margin-bottom: 30px;
    }

    .logo {
      margin-top: 3px;
    }

    .logoText {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 38px;
      color: #680079;
      margin-left: 17px;
    }
  }
}

.wrapMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center !important ;
  min-height: 937px;
  min-width: 360px;
  padding-bottom: 47px;

  .centralCardMobile {
    position: initial;
    font-family: 'Poppins';
    padding: 36px 16px 10px 16px;
    margin-top: 0px;
    width: 343px;
  }

  .title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    width: 100%;
    color: $primary_color;
  }

  .helpText {
    margin: 28px 0 0 0;
    color: $ui_medium_gray;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }

  .form {
    width: 100%;
    margin: 24px 0 0 0;

    .input {
      height: 38px;
      font-family: 'Poppins';
    }

    .signInButton {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    }

    .myDisabledButton {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      opacity: 0.5;
      border-radius: 2px;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-color: #680079 !important;
      opacity: 0.5;
      background: #680079 !important;
    }

    .errorMessage {
      color: #cf1322;
      text-align: center;
      margin: 12px 0 12px 0;
    }
  }

  .legalBlockMobile {
    margin: 140px 0 0px 0;
    color: $ui_medium_gray;
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-content: center;
    justify-content: center;

    a {
      color: $ui_medium_gray;
      text-decoration: underline;
    }

    .questions {
      font-style: normal;
      font-weight: normal;
      font-size: 16px !important;
      line-height: 24px !important;
      text-align: center !important;
      margin: 0 16px 17px 16px;
      align-self: center !important;
      align-content: center !important;
      position: initial;
    }
  }

  .termsAndConditionsLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    .termsAndConditionsLink {
      color: $primary_color;
    }
  }

  .logoContainerMobile {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-content: center;
    justify-content: center;

    .logoMobile {
      align-self: center;
      margin: 53px 0 32px 0;
    }
  }
}

.container {
  height: 100vh;
  background-size: contain;
  align-content: center !important;
}
