@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  flex-direction: column;
  background: #fafafa;

  .text {
    padding: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5a6265;
  }

  .form {
    color: black;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 343px;
    background: #fafafa;

    .text {
      width: 333px;

      /* Paragraph/Small */
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      /* Text/Secondary */

      color: #5a6265;
    }
  }
}
