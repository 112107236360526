@import 'src/styles/colors';

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #30353f;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #5a6265;
    margin: 4px 0 0 0;
  }

  .topBlock {
    height: 96px;
    width: calc(100% - 12px);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 29px 6px 39px 6px;

    .teamInfo {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      margin: 24px;

      .number {
        height: 48px;
        width: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #30353f;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        border-radius: 8px;
      }
      .number1 {
        background-color: #fde29c;
      }
      .number2 {
        background-color: #dadada;
      }
      .number3 {
        background-color: #e1ac88;
      }

      .teamData {
        margin: 17px;
        display: flex;
        flex-direction: column;

        .teamName {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: $primary_color;
        }
        .teamResult {
          color: #5a6265;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
