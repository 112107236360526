@import 'src/styles/colors';

.wrap {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    box-shadow: inset 0px -1px 0px #f0f0f0;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      margin: 0 0 0 24px;
    }
    .closeButton {
      margin: 0 18px 0 0;
      cursor: pointer;
    }
  }

  .searchBar {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 28px 0 32px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .searchField {
      width: 264px;
    }
  }

  .tableWrap {
    margin: 12px 20px 0 24px;

    .memberName {
      color: $primary_color;
    }
  }

  .bottomBar {
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    box-shadow: inset 0 1px 0 #f0f0f0;

    .okButton {
      color: $primary_color;
      margin: 0 10px 0 0;
    }
  }
}

.modal {
  width: calc(100% - 214px);
  min-height: calc(100% - 214px);
}

.challengeTitle {
  display: flex;
  flex-direction: row;
  align-items: center;

  .image {
    height: 36px;
    width: 36px;
    margin: 0 8px 0 0;
    overflow: hidden;
  }
}

.totalUsers {
  display: flex;
  flex-direction: row;
  align-items: center;

  .usersNumber {
    margin: 0 4px 0 0;
  }
  .usersPercent {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
  }
}

.typeIcon {
  margin-right: 8px;
  font-size: 18px;
  color: $ui_gray;
}
