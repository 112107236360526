@import 'src/styles/colors';

.pageHeader {
  padding: 0;
  margin: 0 0 12px 0;
  font-family: sans-serif;

  .lastItem {
    color: $primary_color;
  }
}
