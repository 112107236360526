@import 'src/styles/colors';

.layout {
  height: 90%;
  align-items: center;
  justify-content: center;
  background: #fafafa !important;

  .emptyIcon {
    align-self: center;
    justify-content: center;
  }

  .emptyText {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-top: 25px;
    text-align: center;
  }

  .signInButton {
    align-items: center;
    align-self: center;
    border-radius: 2px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    display: flex;
    flex-direction: row;
    height: 38px;
    justify-content: center;
    margin-top: 16px;
    width: 159px;
  }
}

.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Poppins';
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }
    }
  }
}

.tableActions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 102px;

  .actionButton {
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 4px 0 4px;
  }
  .actionButtonPrimary {
    color: #0050b3;
  }
}

.bulkMenu {
  margin: 0 0 12px 0;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background-color: $ui_light_color;
  border-radius: 15px;

  .bulkMenuCounter {
    margin: 0 48px 0 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .bulkMenuDropdown {
    margin: 0 8px 0 0;
  }
}

:global {
  .ant-table-thead {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
  }
}
