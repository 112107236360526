.columns {
  display: flex;
  flex-direction: row;

  .column {
    flex: 1;
    overflow: auto;
  }
  .divider {
    width: 37px;
  }
}
