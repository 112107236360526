@import 'src/styles/colors';

.toast {
  background: none !important;
  box-shadow: none !important;
}
.alertSuccess {
  background-color: #d9f7be !important;
}

.alertInfo {
  background-color: $ui_medium_gray !important;
  color: #ffffff !important;
  :global {
    .ant-alert-message {
      color: #ffffff !important;
    }
  }

  .icon {
    color: #ffffff !important;
  }
}
