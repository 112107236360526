@import 'src/styles/colors';

.actionColumn {
  padding: 0 !important;
  text-align: center !important;

  .tableActions, .tableControl {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 104px;

    .actionButton {
      cursor: pointer;
      margin: 9px;
    }
    .actionButtonInfo {
      color: #680079
    }
    .actionButtonPrimary {
      color: #0050b3;
      margin: 9px;
      cursor: pointer;
    }
    .actionDelete {
      cursor: pointer;
      color: #F5222D;
      user-select: none;
    }
  }
  .tableControl {
    align-items: flex-start;
    width: inherit;
  }
}

.searchBarContainer {
  padding: 14px 0 28px 0;

  .conrolBlock {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .searchField {
    max-width: 300px;
    float: right;
    margin-right: 16px;

    :global {
      .anticon-search {
        color: $primary_color;
      }
    }
  }
}

.emptyContainer {
  height: 100%;

  .empty {
    background-color: #fafafa;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.bulkMenu {
  margin: 0 0 12px 0;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background-color: $ui_light_color;
  border-radius: 15px;

  .bulkMenuCounter {
    margin: 0 48px 0 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .bulkMenuDropdown {
    margin: 0 8px 0 0;
    width: 150px;
  }
}
