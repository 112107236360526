@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .text {
    height: 38px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    margin-left: 8px;
    /* identical to box height, or 211% */

    display: flex;
    align-items: center;
    letter-spacing: 0.005em;

    /* Complementary/Plum */

    color: #9d50ef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
