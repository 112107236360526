@import 'src/styles/colors';

.wrap {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    box-shadow: inset 0px -1px 0px #f0f0f0;

    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      margin: 0 0 0 24px;
    }
    .closeButton {
      margin: 0 18px 0 0;
      cursor: pointer;
    }
  }

  .searchBar {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 28px 0 32px;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .searchField {
      width: 220px;
    }
  }

  .tableWrap {
    margin: 12px 20px 0 24px;

    .memberName {
      color: $primary_color;
    }
  }

  .bottomBar {
    height: 52px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-shadow: inset 0px 1px 0px #f0f0f0;

    .okButton {
      color: $primary_color;
      margin: 0 10px 0 0;
    }
  }
}

.modal {
  width: 664px;
}

:global(.modalContainer) {
  position: relative !important;
}
