.wrap {
  display: flex;
  flex-direction: row;
  width: 100%;

  .leaderboardWrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #30353f;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #5a6265;
      margin: 4px 0 0 0;
    }

    .table {
      margin: 25px 0 0 0;

      .number {
        height: 18px;
        width: 18px;
        border-radius: 10px;
        background-color: #f4f3f3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
      }
      .number1 {
        background-color: #ffe58f;
      }
      .number2 {
        background-color: #d9d9d9;
      }
      .number3 {
        background-color: #faad14;
      }
    }
  }
  .dataColumn {
    margin: 0 0 0 55px;
    min-width: 311px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    .dataBlock {
      margin: 23px 0 0 0;

      .statusWrapper {
        display: flex;
        flex-direction: row;
      }

      .dataBlockValue {
        color: #00000073;
      }
    }
  }
}
