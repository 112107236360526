.wrap {
  height: 100%;

  .wave {
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    position: fixed;
    z-index: -1;
  }

  .waveMobile {
    width: 100%;
    min-width: 360px;
    min-height: 970px;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
    position: absolute;
    z-index: -1;
  }

  .content > div {
    height: 100%;
  }

  .content {
    height: 100%;
  }
}
