@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  background: #FEF6F6;
  height: 100vh;
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .rowLeft {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;

    .progressPosition {
      position: fixed;
      top: 0px;
      background-color: #FEF6F6;
      z-index: 10;
      height: 50px;

      .logoPos {
        position: relative;
        left: -60px;
        top: 20px;
      }

      .circularActive {
        display: flex;
        box-sizing: border-box;
        background: #9d50ef;
        border: 1px solid #9d50ef;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularUnActive {
        display: flex;
        box-sizing: border-box;
        border: 1px solid #bdc0c1;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .activeText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #9d50ef;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .unActiveText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #5a6265;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .numberActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .numberUnActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #bdc0c1;
      }

      .line {
        width: 45px;
        height: 0px;
        border: 0.5px solid #bdc0c1;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 12px;
      }
    }

    .innerRowLeft {
      display: flex;
      flex-direction: column;
      width: 345px;
      align-items: center;

      .HeadingRes {
        display: flex;
        max-width: 345px;
        flex-wrap: wrap;
        margin-top: -100px;
      }

      .calContainer {
        display: flex;
        flex-direction: column;
        margin-top: 31px;
        width: 100%;

        .selectDate {
          margin-bottom: 11px;
        }
      }

      .align {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 24px;

        .button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          width: 345px;
          height: 40px;
          background: #1d1d1b;
          mix-blend-mode: normal;
          border-radius: 100px;
          color: #fff;
          margin-top: 45px;
        }

        .myDisabledButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          width: 345px;
          height: 40px;
          border-color: #1d1d1b;
          opacity: 0.5;
          background: #1d1d1b;
          mix-blend-mode: normal;
          border-radius: 100px;
          color: #fff;
          margin-top: 48px;
        }
      }
    }

    .footer {
      position: fixed;
      bottom: 20px;
    }
  }

  .rowRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #FFEBE5;
    overflow: hidden;

    .textPos {
      width: 513px;
      height: 192px;
      align-items: center;

      .textStyle {
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 70px;
        line-height: 96px;
        text-align: center;
        color: #1d1d1b;
      }
    }
  }
}

.image {
  margin-top: 13px;
  max-width: 102px;
  min-width: 50px;
}

// Tablet

@media only screen and (max-width: 876px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    align-items: center;
    align-self: center;
    height: 100vh;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
    }

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      align-items: center;

      .progressPosition {
        .logoPos {
          position: relative;
          left: -60px;
          top: 20px;
        }
      }
    }

    .rowRight {
      display: none;

      .textPos {
        display: none;

        .textStyle {
          display: none;
        }
      }
    }
  }
}

// Mobile

@media only screen and (max-width: 576px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    align-items: center;
    align-self: center;
    height: 100vh;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
    }

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100vh;
      align-items: center;
      justify-content: center;

      .progressPosition {
        position: fixed;
        top: 0px;
        width: 100%;
        background-color: #fff;
        z-index: 10;
        height: 100px;

        .logoPos {
          position: relative;
          left: 140px;
          top: 10px;
        }

        .circularActive {
          display: flex;
          box-sizing: border-box;
          background: #9d50ef;
          border: 1px solid #9d50ef;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .circularUnActive {
          display: flex;
          box-sizing: border-box;
          border: 1px solid #bdc0c1;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .activeText {
          display: none;
        }

        .unActiveText {
          display: none;
        }

        .numberActive {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        .numberUnActive {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #bdc0c1;
        }

        .line {
          width: 51px;
          height: 0px;
          border: 0.5px solid #bdc0c1;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-left: 22px;
          margin-right: 22px;
          margin-top: 12px;
        }
      }

      .innerRowLeft {
        display: flex;
        flex-direction: column;
        width: 335px;
        align-items: center;

        .HeadingRes {
          position: relative;
          top: 70px;
          width: 380px;
        }

        .margin {
          margin: 20px;
        }

        .align {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }

    .rowRight {
      display: none;

      .textPos {
        display: none;

        .textStyle {
          display: none;
        }
      }
    }
  }
}
