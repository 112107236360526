@import 'src/styles/colors';

.badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(105, 170, 158, 0.1);
  border-radius: 45px;
  padding: 0 8px;
  color: #69aa9e;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  .icon {
    margin-right: 4px;
  }
}

.badgeNegative {
  @extend .badge;

  color: #ef7065;
  background: rgba(239, 112, 101, 0.1);
}
