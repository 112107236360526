@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  background: #ffffff;
  height: 100vh;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .rowLeft {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;

    .progressPosition {
      position: fixed;
      top: 0px;
      background-color: #ffffff;
      z-index: 10;
      height: 100px;
      width: 50%;

      .logoPos {
        position: relative;
        left: 0px;
        top: 20px;
      }

      .circularActive {
        display: flex;
        box-sizing: border-box;
        background: #9d50ef;
        border: 1px solid #9d50ef;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularCompleted {
        display: flex;
        box-sizing: border-box;
        background: #64db94;
        border: 1px solid #64db94;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .circularUnActive {
        display: flex;
        box-sizing: border-box;
        border: 1px solid #bdc0c1;
        height: 24px;
        width: 24px;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .completedText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #1d1d1b;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .activeText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #9d50ef;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .unActiveText {
        margin-left: 8px;
        height: 20px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #5a6265;

        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .numberOne {
        color: #fff;
      }

      .numberActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .numberUnActive {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #bdc0c1;
      }

      .line {
        width: 45px;
        height: 0px;
        border: 0.5px solid #bdc0c1;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 12px;
      }

      .lineActive {
        width: 45px;
        height: 0px;
        border: 0.5px solid #64db94;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 12px;
      }
    }

    .innerRowLeft {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      width: 345px;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-top: 60px;

      .button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 465px;
        height: 40px;
        background: #1d1d1b;
        mix-blend-mode: normal;
        border-radius: 2px;
        color: #fff !important;
        margin-top: 48px;
      }

      .button:hover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 465px;
        height: 40px;
        border-color: #bdc0c1 !important;
        opacity: 0.5;
        background: #bdc0c1 !important;
        mix-blend-mode: normal;
        border-radius: 2px;
        color: #fff !important;
        margin-top: 48px;
      }

      .textContainer {
        height: 72px;

        .text {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #5a6265;
        }
      }

      .innerContainer {
        height: 224px;
        background: #fafafa;
        border-radius: 8px;
        padding: 22px;
        margin: 20px auto;

        .heading {
          width: 389px;
          height: 24px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #1d1d1b;
        }

        .paragraph {
          width: 418px;
          height: 43px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #5a6265;
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }

      .guarantees {
        margin: 24;
        .name {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;

          /* Primary/Caviar black */
          color: #1d1d1b;
        }

        .description {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          /* or 143% */

          /* Text/Secondary */
          color: #5a6265;
        }
      }
    }

    .footer {
      position: relative;
      top: 0px;

      .footerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 390px;
        height: 80px;
        margin-top: 10px;

        a > span {
          color: #5a6265;
          background-color: transparent;
          text-decoration: underline;
        }

        .policy {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .copyrightUnderline {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          /* or 167% */

          text-align: center;

          /* UI Colors/Medium gray */

          color: #5a6265;

          /* Inside auto layout */

          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

  }

  .rowRight {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: $light_purple;
    overflow: hidden;

    .textPos {
      display: flex;
      position: absolute;
      width: 380px;
      height: 192px;
      align-items: center;

      .textStyle {
        font-family: 'DM Serif Display';
        font-style: normal;
        font-weight: 400;
        font-size: 70px;
        line-height: 96px;
        text-align: center;
        color: #1d1d1b;
      }
    }
  }
}

// Tablet

@media only screen and (max-width: 876px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    align-items: center;
    align-self: center;
    height: 100vh;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
    }

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      align-items: center;

      .progressPosition {
        position: fixed;
        top: 0px;
        width: 100%;
        background-color: #fff;
        z-index: 10;
        height: 100px;
      }
    }

    .rowRight {
      display: none;

      .textPos {
        display: none;

        .textStyle {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 100vh;
    width: 100%;

    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
      padding: 20px;
    }

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: center;
      justify-content: center;

      .progressPosition {
        position: fixed;
        top: 0px;
        width: 100%;
        background-color: #fff;
        z-index: 10;
        height: 100px;

        .logoPos {
          position: relative;
          left: 100px;
          top: 10px;
        }

        .circularActive {
          display: flex;
          box-sizing: border-box;
          background: #9d50ef;
          border: 1px solid #9d50ef;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .circularCompleted {
          display: flex;
          box-sizing: border-box;
          background: #64db94;
          border: 1px solid #64db94;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .circularUnActive {
          display: flex;
          box-sizing: border-box;
          border: 1px solid #bdc0c1;
          height: 24px;
          width: 24px;
          border-radius: 32px;
          justify-content: center;
          align-items: center;
          color: #fff;
        }

        .completedText {
          display: none;
        }

        .activeText {
          display: none;
        }

        .unActiveText {
          display: none;
        }

        .numberActive {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        .numberUnActive {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #bdc0c1;
        }

        .line {
          width: 45px;
          height: 0px;
          border: 0.5px solid #bdc0c1;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-left: 22px;
          margin-right: 22px;
          margin-top: 12px;
        }

        .lineActive {
          width: 45px;
          height: 0px;
          border: 0.5px solid #64db94;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-left: 22px;
          margin-right: 22px;
          margin-top: 12px;
        }
      }

      .innerRowLeft {
        display: flex;
        height: 100vh;
        width: 346px;
        align-items: center;
        flex-direction: column;
        margin-top: 280px;

        .button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;
          width: 346px;
          height: 40px;
          background: #1d1d1b;
          mix-blend-mode: normal;
          border-radius: 2px;
          color: #fff !important;
          margin-top: 48px;
        }

        .textContainer {
          display: flex;
          flex-direction: column;
          width: 333px;
          height: 59px;
          margin: auto;
          margin-left: 16px;

          .text {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #5a6265;
          }
        }

        .innerContainer {
          width: 345px;
          height: 284px;
          background: #fafafa;
          border-radius: 8px;

          .heading {
            width: 345px;
            height: 24px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #1d1d1b;
          }

          .paragraph {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 305px;
            height: 103px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #5a6265;
          }
        }
      }

      .footer {
        position: relative;
        top: 120px;

        .footerContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 375px;
          height: 40px;
          height: 80px;
          padding: 30px;
          margin-bottom: 30px;

          a > span {
            color: #5a6265;
            background-color: transparent;
            text-decoration: underline;
          }

          .policy {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            color: #5a6265;
            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
          }
        }
      }
    }

    .rowRight {
      display: none;

      .textPos {
        display: none;

        .textStyle {
          display: none;
        }
      }
    }
  }
}
