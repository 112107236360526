@import 'src/styles/colors';

.formWrap {
  display: flex;
  flex-direction: row;
  width: 100%;

  .card {
    flex: 1;

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: $primary_color;
      margin-bottom: 22px;
    }
    .basicInfoContainer {
      margin: 0 0 49px 0;
      display: flex;
      flex-direction: row;

      .basicInfo {
        flex: 1;
        margin: 0 34px 0 0;

        .form {
          .typePointsContainer {
            display: flex;
            flex-direction: row;

            .typeContainer {
              flex: 1;
              margin: 0 18px 0 0;
            }

            .pointsContainer {
              flex: 1;
              margin: 0 0 0 18px;

              .pointsInput {
                width: 100%;
              }
            }
          }
        }
      }
      .imageForm {
        flex: 1;
        margin: 0;
      }
    }
  }
  .dataColumn {
    margin: 0 0 0 55px;
    min-width: 311px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    .dataBlock {
      margin: 23px 0 0 0;

      .statusWrapper {
        display: flex;
        flex-direction: row;
      }

      .dataBlockValue {
        color: #00000073;
      }
    }
  }
}

.inputWithLengthCounter {
  :global(.ant-input-suffix) {
    position: absolute;
    right: 0;
    bottom: -23px;
  }
}
