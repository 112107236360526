.wrap {
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .bannerWrap {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    background-color: #fff5ea66;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    border-radius: 8px;

    .welcome {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      text-align: left;
      color: #680079;
      margin: 32px 0 0 60px;
    }
    .welcomeSecondLine {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      text-align: left;
      color: #680079;
      margin: 0 0 0 60px;
    }

    .date {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #5a6265;
      margin: 16px 0 24px 60px;
    }
  }

  .overall {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 32px 0 0 0;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
    }
    .tileWrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 8px -18px 0 -18px;
      width: calc(100% + 36px);
      padding: 0 6px 0 6px;

      .tilePairs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: -24px;

        .tile {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 144px;
          min-width: 270px;
          cursor: pointer;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
          border-radius: 12px;
          padding: 20px 22px 22px 20px;
          margin: 0 12px 24px 12px;

          .tileTitle {
            display: flex;
            flex-direction: row;
            align-items: center;

            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            text-align: left;

            .iconWrap {
              height: 44px;
              width: 44px;
              background-color: #f8f2f8;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 0 12px 0 0;

              .icon {
                height: 24px;
                width: 24px;

                svg {
                  max-height: 24px;
                }
              }
            }
          }
          .tileContent {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            margin: 10px 0 0 0;

            .data {
              font-size: 32px;
              font-weight: 500;
              line-height: 48px;
              text-align: left;
            }
            .total {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin: 0 0 0 14px;
              color: #5a6265;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              width: 100%;

              .totalTitle {
                flex: 1;
              }
              .row {
                height: 24px;
              }
            }
          }
        }

        .shortcut {
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
          border-radius: 12px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 80px;
          min-width: 210px;
          flex: 1;
          cursor: pointer;
          margin: 0 12px 0 12px;

          .icon {
            margin: 0 16px 0 0;
          }

          .shortcutTitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
          }
        }
      }
    }
  }

  .helpCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 20px;
    height: 64px;
    width: 100%;
    background-color: #f4f3f366;
    border-radius: 8px;
    margin: 50px 0 20px 0;

    .icon {
      height: 24px;
      margin: 0 8px 0 0;
    }

    .needHelp {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #30353f;
      margin: 0 8px 0 0;
    }
    .contactUs {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #5a6265;

      .hepCenterLink {
        margin: 0;
        padding: 0;
      }
    }
  }
}
