@import 'src/styles/colors';

.layout {
  margin-top: 20px;
  .content {
    padding: 0 -30px 0 0px;
    background-color: #ffffff;

    .card {
      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $primary_color;
      }

      .form {
        .input {
          flex: 1;
          margin: 20px 0 0 0;
          width: 45%;
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #30353f;
}

.buttons {
  margin: 40px 0 0 0;

  .button {
    margin: 0 15px 0 0;
  }
}

.overViewContainer {
  margin: 20px 30px 0 30px;
}

.overviewTitle {
  font-family: 'Poppins';
  font-weight: 400;
  size: 14px;
  line-height: 22px;
}

.overviewOptionContainer {
  padding: 16px 0 16px 0;
}

.overviewOptionTitle {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
