@import 'src/styles/colors';
@import 'src/styles/font';

:global {
  .ant-carousel .slick-dots li button {
    background: #1D1D1B;
    opacity: 0.4;
  }

  .ant-carousel .slick-dots li.slick-active button {
      opacity: 1;
      background: #1D1D1B;
  }
}

.carousel {
  width: 50%;
  background: #FFEBE5;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.textContainer {
  background: #FFEBE5;
  .carouselText {
    line-height: 40px;
    text-align: center;
    color: #1d1d1b;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    font-family: 'DM Serif Display';
    max-width: 554px;
    margin: auto;
  }
}


@media only screen and (max-width: 576px) {
  .carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel {
    display: none;
  }
}
