.back {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #262626b3;
  z-index: 1000;

  .modal {
    position: absolute;
    top: 0;
    height: 100%;
    background: #ffffff;
    padding: 0 18px 0 23px;
    overflow: scroll;
  }
}
