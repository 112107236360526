@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 390px;
  height: 80px;

  a > span {
    color: #5a6265;
    background-color: transparent;
    text-decoration: underline;
  }

  .policy {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #5a6265;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .copyrightUnderline {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* or 167% */

    text-align: center;

    /* UI Colors/Medium gray */

    color: #5a6265;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  bottom: -200px;
  height: 80px;

  .footerText {
    width: 400px;
    height: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #1d1d1b;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media only screen and (max-width: 876px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 3750px;
    height: 40px;
    height: 80px;
    padding: 30px;
    margin-bottom: 30px;

    a > span {
      color: #5a6265;
      background-color: transparent;
      text-decoration: underline;
    }

    .policy {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #5a6265;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }
  }
}
