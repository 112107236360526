@import 'src/styles/colors';

.wrap {
  height: 100%;
}

.emptyBack {
  height: calc(100vh - 150px);
  padding: 24px;
  background-color: #fafafa;
  border-radius: 10px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin: 0 0 17px 0;
    color: $primary_color;
  }

  .empty {
    background-color: #ffffff;
    border-radius: 10px;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .noUserButton {
      color: $primary_color;
    }

    :global {
      .ant-empty-image {
        height: 160px;
      }
    }
  }
}

.assignButton {
  color: $primary_color !important;
}

.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }
    }
    .manageButton {
      margin: 0 8px 0 0;
      color: $primary_color;
    }
  }
}
