@import 'src/styles/colors';

.itemLeftContainer {
  flex: 1;
  margin: 0 18px 32px 0;
}

.inputError {
  border: 1px solid $danger_color;

  &:focus {
    border-color: $danger_color;
  }
}

.companyDetailsValue {
  font-weight: 500;
}
