@import 'src/styles/colors';

.formTitle {
  margin: 20px 0 0 0;
}
.fieldWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 0 0;

  .item {
    flex: 1;
    margin-right: 18px;

    :global {
      .ant-picker {
        width: 100%;
      }
    }

    .dateInput {
      width: 100%;

      :global {
        .ant-picker-suffix {
          color: $primary_color;
        }
      }
    }
  }
}
.descAndImage {
  justify-content: flex-start;
  .textAreaForm {
    width: 450px;
    margin-right: 30px;
  }

  .ant-form-item {
    width: 300px;
  }
}
.toolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  .toolBarTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchField {
      max-width: 264px;
      width: 264px;
      margin: 0 16px 0 0;
      :global {
        .anticon-search {
          color: $primary_color;
        }
      }
    }
    .manageButton {
      margin: 0 8px 0 0;
      color: $primary_color;
    }
  }
}
.productInfo {
  width: 100%;
  margin: 0 0 50px 25px;

  .title {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin: 0 0 24px 0;
  }
  .dataWrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .dataRow {
      display: flex;
      flex-direction: row;
      margin: 0 0 47px 0;

      .dataBlock {
        flex: 1;
        display: flex;
        flex-direction: column;

        .dataTitle {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          margin: 0 0 4px 0;
        }
        .dataValue {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
          color: #00000073;
        }
      }
    }
  }
}

.wrgGeneralLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}

.wrgToggle {
  touch-action: pan-x;
  display: flex;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.wrgToggleText {
  margin-left: 13px;
}

.wrgTextLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.wrgSubLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8C8C8C;
}
