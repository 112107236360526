@import 'src/styles/colors';

.header {
  padding-left: 0;
  padding-right: 0;
}

.cancelButton {
  color: $primary_color;
}

.confirmIcon {
  color: #1890ff !important;
}
