.card {
  position: absolute;
  left: calc(50% - 216px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 52px 0 0 0;

  width: 432px;
  padding: 36px;
  box-shadow: 0 0 50px 10px rgba(186, 186, 186, 0.25);
  border-radius: 20px;
  background: #ffffff;
}
