@import 'src/styles/colors';

.header {
  padding-left: 0;
  padding-right: 0;
}

.cancelButton {
  color: $primary_color;
}

.wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 900px;
}
.tabs {
  flex: 1;
  min-width: 906px;
}
