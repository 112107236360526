.colorWrap {
  width: 72px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .color {
    height: 24px;
    width: 64px;
    border-radius: 2px;
  }
}

.globalButton {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.colorPicker {
  position: fixed;
  z-index: 1;
  padding-bottom: 1px;
}

.cursorPointer {
  cursor: pointer;
}
