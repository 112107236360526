@import 'src/styles/colors';

.itemLeftContainer {
  flex: 1;
  margin: 0 18px 32px 0;
}

.companyActivity,
.companyCategory {
  max-width: calc(50% - 9px);
}

.companyDetailsValue {
  font-weight: 500;
}
