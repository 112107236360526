@import 'src/styles/colors';

.bulkMenu {
  margin: 0 0 12px 0;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background-color: $ui_light_color;
  border-radius: 15px;

  .bulkMenuCounter {
    margin: 0 48px 0 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .bulkMenuDropdown {
    margin: 0 8px 0 0;
  }
}
