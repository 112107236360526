@import 'src/styles/colors';

.layout {
  margin-top: 20px;
  .content {
    padding: 0 -30px 0 0px;
    background-color: #ffffff;

    .card {
      .title {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $primary_color;
      }
      .sectionContainer {
        display: flex;
        flex-direction: row;
        width: 100%;

        .basicInfo {
          flex: 1;
          margin: 22px 0 0 0;
          width: 100%;

          .form {
            .basicRowContainer {
              display: flex;
              flex-direction: row;
              margin-top: 10px;

              .itemLeftContainer {
                flex: 1;
                margin: 0 18px 0 0;
              }
              .itemRightContainer {
                flex: 1;
                margin: 0 0 0 0px;
              }
            }
          }
        }
        .imageForm {
          flex: 1;
          margin-top: 30px;
        }
      }
    }
  }
}

.container {
  display: flex;
  min-height: calc(100% - 75px);

  .tabs {
    min-height: 100%;
    width: 100%;

    :global {
      .ant-tabs-content-holder {
        .ant-tabs-content {
          min-height: 100%;
        }
      }
    }
  }
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #30353f;
  padding-top: 20px;
}

.buttons {
  margin: 40px 0 0 0;

  .button {
    margin: 0 15px 0 0;
  }
}

.informationTabContainer {
  display: flex;
  flex-direction: row;
}

.companyOverViewContainer {
  margin: 40px 30px 0 30px;
}

.overviewTitle {
  font-family: 'Poppins';
  font-weight: 400;
  size: 14px;
  line-height: 22px;
}

.overviewOptionContainer {
  padding: 16px 0 16px 0;
}

.overviewOptionTitle {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.requestUpdateButton {
  color: $primary_color;
}

.companyPage {
  position: relative;
  height: 100%;
  overflow: auto;
}
