@import 'src/styles/colors';

.dropdown {
  .deleteButton {
    color: $danger_color;
  }
}

.defaultButton {
  color: $primary_color;
}

.modalTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.formContainer {
  margin: 34px 0;
  display: flex;
  flex-direction: row;

  .formCard {
    flex: 1;

    .formItem {
      margin: 32px 0 0 0;
    }
  }

  .insightDataColumn {
    margin: 0 0 0 55px;
    width: 311px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    .dataBlock {
      margin: 23px 0 0 0;

      .dataBlockValue {
        color: #00000073;
      }
    }
  }
}
