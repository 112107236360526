.enrolledUsersWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 22px 0 0 0;

  .header {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }

    .searchField {
      width: 220px;
    }
  }

  .tableWrapper {
    width: 100%;
    background-color: #ffffff;
    padding: 25px 25px 20px 25px;
  }
}
