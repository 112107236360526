@import 'src/styles/colors';
@import 'src/styles/font';

.container {
  display: flex;
  background: #fef6f6;
  height: 100vh;
  width: 100%;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .rowLeft {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 100vh;
      align-items: center;
      justify-content: center;
      z-index: 60;
      background-color: #fef6f6;

      .header {
        display: flex;
        position: absolute;
        flex-direction: row;
        top: 20px;
        width: 47%;

        .langText {
          margin-left: 4px;
        }

        .logoPos {
          display: flex;
          justify-content: flex-start;
        }

        .headerContainer {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
}

.innerRowLeft {
  width: 430px;
  .textContainer {
    margin-bottom: 20px;
    .titleText {
      width: 345px;
      height: 119px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 50px;
      color: $caviar_black;
    }
  }

  .contacts {
    margin-top: 60px;
  }

  .footer {
    position: relative;
    align-items: center;
    top: 90px;
    width: 400px;

    .footerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 80px;
      margin-top: 10px;

      a > span {
        color: #5a6265;
        background-color: transparent;
        text-decoration: underline;
      }

      .policy {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #5a6265;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      .copyrightUnderline {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #5a6265;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin-top: -10px;
      }
    }
  }
}

.rowRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #ffebe5;
  overflow: hidden;
}

// Mobile & Tablet

@media only screen and (max-width: 876px) {
  .container {
    display: flex;
    flex-direction: column;
    background: #fef6f6;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
    height: 110%;

    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-between;
      background: #fef6f6;

      .rowLeft {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #fef6f6;

        .header {
          display: flex;
          position: absolute;
          flex-direction: row;
          top: 5px;
          width: 95%;

          .langText {
            margin-left: 4px;
          }

          .logoPos {
            display: flex;
            justify-content: flex-start;
          }

          .headerContainer {
            display: flex;
            justify-content: flex-end;
            width: 100%;
          }
        }
      }
    }
  }

  .innerRowLeft {
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 390px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fef6f6;

    .textContainer {
      .titleText {
        font-size: 28px;
      }
    }

    .footer {
      position: relative;
      top: 40px;
      width: 400px;

      .footerContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 375px;
        height: 40px;
        height: 80px;
        padding: 30px;
        margin-bottom: 30px;
        margin-top: -20px;

        a > span {
          color: #5a6265;
          background-color: transparent;
          text-decoration: underline;
        }

        .policy {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .copyrightUnderline {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #5a6265;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
          margin-top: -10px;
        }
      }
    }
  }

  .rowRight {
    display: none;
  }
}
