@import 'src/styles/colors';

.actionColumn {
  padding: 0 !important;
  text-align: center !important;

  .tableActions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 104px;

    .actionButton {
      cursor: pointer;
      margin: 9px;
    }
    .actionButtonPrimary {
      color: #0050b3;
      margin: 9px;
      cursor: pointer;
    }
  }
}

.buttonBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 25px 25px 18px 5px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  .autocompleField {
    max-width: 264px;
    width: 264px;
  }

  .searchField {
    margin: -4px 0 0 0;
    :global {
      .anticon-search {
        color: $primary_color;
      }
    }
  }

  .addButton {
    color: $primary_color;
    margin: 0 8px 0 8px;
  }
  .manageButton {
    color: $primary_color;
  }
  .cancelButton {
    color: $primary_color;
    margin: 0 0 0 8px;
  }
}

.empty {
  background-color: #fafafa;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .noUserButton {
    color: $primary_color;
  }
}

.tabs {
  height: 100% !important;

  .tab {
    height: 100% !important;
    overflow: auto;
    padding-right: 30px;
  }

  :global {
    .ant-tabs-content-holder {
      height: 100% !important;

      .ant-tabs-content {
        height: 100% !important;
      }
    }
  }
}

.table {
  margin: 0 25px 0 0;

  .genderField {
    min-width: 95px;
  }
}

.searchResutl {
  font-weight: normal;
}

.autocompleteField {
  width: 400px;
}
